
  import { defineComponent, ref } from 'vue'
  import { mapActions, mapState } from 'vuex'
  import { PartnerService } from '@/services/PartnerService'
  import { IntegrationService } from '@/services/IntegrationService'
  import {
    ShopOutlined,
    UserOutlined,
    CopyOutlined,
    ReadOutlined,
    InfoCircleOutlined,
    KeyOutlined
  } from '@ant-design/icons-vue'
  import { notification } from 'ant-design-vue'
  import { TableState, TableStateFilters } from 'ant-design-vue/es/table/interface'

  type Pagination = TableState['pagination'];

  export default defineComponent({
    name: 'PartnerCustomersDatatable',
    props: {
      partnerHash: String,
      isFeatureApi: Boolean
    },
    components: {
      ShopOutlined,
      UserOutlined,
      CopyOutlined,
      ReadOutlined,
      InfoCircleOutlined,
      KeyOutlined
    },
    watch: {
      partnerHash: async function (newPartnerHash) {
        if (newPartnerHash !== '') {
          await this.fetchPartnersPlans(newPartnerHash)
          await this.fetchPartnerCustomers(newPartnerHash, null, null, null, null)
        }
      }
    },
    computed: {
      ...mapState('configuracao', ['partnerCustomers']),
      listPartnerCustomers (): any[] {
        return this.partnerCustomers !== null ? this.partnerCustomers : []
      }
    },
    data: () => ({
      showActions: ref<boolean>(true),
      statusFilterList: ref<any>(null),
      columns: [
        {
          title: 'Cliente',
          dataIndex: 'name',
          key: 'name',
          slots: { customRender: 'name' }
        },
        {
          title: 'Vencimento',
          dataIndex: 'expirationDate',
          slots: { customRender: 'expirationDate' },
          key: 'expirationDate',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          width: '10%'
        },
        {
          title: 'Status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
          filters: [
            {
              text: 'Ativo',
              value: 'Active'
            },
            {
              text: 'Vencido',
              value: 'Expired'
            },
            {
              text: 'Pendente',
              value: 'Pending'
            }
          ],
          width: '10%'
        },
        {
          title: 'Plano',
          dataIndex: 'plan',
          slots: { customRender: 'plan' },
          key: 'plan',
          sorter: false,
          filters: []
        },
        {
          title: 'Chaves',
          dataIndex: 'action',
          slots: { customRender: 'action' },
          key: 'action',
          sorter: false,
          filters: [],
          fixed: 'right',
          width: '6%'
        }
      ],
      partnerService: ref<PartnerService>(),
      customLocale: {
        filterConfirm: 'Ok',
        filterReset: 'Limpar',
        sortTitle: 'Ordenar',
        filterTitle: 'Filtrar'
      },
      isShowKeysClient: false,
      clientHash: ref('')
    }),
    async updated () {
      if (!this.isFeatureApi) {
        this.columns.pop()
      }
    },
    methods: {
      ...mapActions('configuracao', ['setPartnerCustomers']),
      async fetchPartnerCustomers (partnerHash: any, pageNumber: any, pageSize: any, order: any, filterStatus: any) {
        const customers = (await PartnerService.getInstance().getPartnerCustomers(partnerHash, pageNumber, pageSize, order, filterStatus)) as any
        if (customers !== null) {
          this.mountPartnerCustomers(customers)
        } else {
          this.setPartnerCustomers([])
        }
      },
      async mountPartnerCustomers (customers: any) {
        const partnerCustomers = [] as any
        customers.forEach((element: any) => {
          let status = ''

          switch (element.status) {
            case 1:
              status = 'Ativo'
              break
            case 2:
              status = 'Vencido'
              break
            case 3:
              status = 'Pendente'
              break
          }

          this.statusFilterList = {
            text: status,
            value: element.status
          }
          const it = {
            name: element.customerName,
            expirationDate: element.expiredAt === null ? '--/--/----' : element.expiredAt,
            status: status,
            plan: element.plan,
            accountType: element.accountType,
            id: element.customersAccountId
          }
          partnerCustomers.push(it)
        })

        this.setPartnerCustomers(partnerCustomers)
      },
      async handleTablePartner (pag: Pagination, filters: TableStateFilters, sorter: any) {
        let status = null
        if (filters.status !== undefined) {
          status = filters.status.join(',')
        }

        this.fetchPartnerCustomers(
          this.partnerHash,
          pag?.current,
          pag!.pageSize!,
          sorter.order,
          status)
      },
      async fetchPartnersPlans (partnerHash:string) {
        const plans = await PartnerService.getInstance().getPlansPartner(partnerHash)
        this.columns[this.columns.length - 2].filters = plans.map(x => ({ text: x.name, value: x.id.toString() }))
      },
      async showModalKeysPartnerCustomer (item: any, partnerHash: any) {
        const response = await IntegrationService.getInstance().getIntegrationPartnerClientData(partnerHash, item.id)
        if (response) {
          this.isShowKeysClient = true
          this.clientHash = response.clientHash
        } else {
          this.isShowKeysClient = false
          this.clientHash = ''
        }
      },
      async copyClientHash () {
        if (navigator.clipboard && window.isSecureContext) {
          await navigator.clipboard.writeText(this.clientHash)
          notification.success({
            message: 'O X-Client Id foi copiado com sucesso!'
          })
        } else {
          const textarea = document.createElement('textarea')
          textarea.value = this.clientHash
          textarea.style.position = 'absolute'
          textarea.style.left = '-99999999px'
          document.body.prepend(textarea)
          textarea.select()
          try {
            document.execCommand('copy')
          } catch (err) {
            console.log(err)
          } finally {
            textarea.remove()
            notification.success({
              message: 'O X-Client Id foi copiado com sucesso!'
            })
          }
        }
      }
    }
  })
